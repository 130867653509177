import React from "react";
import styled from "styled-components";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Container, Row, Column } from "src/theme/layout";
import { Typography, Svg } from "src/theme/elements";
import HistoryFeature from "./elements/HistoryFeature";
import RemoteFeature from "./elements/RemoteFeature";
import Quote from "./elements/Quote";
import RealtimeFeature from "./elements/RealtimeFeature";
import { colors } from "src/theme/variables";

const StyledRoot = styled.section.attrs({
  "data-show-header": true,
})`
  position: relative;
  background-color: ${colors.white};
`;

const StyledContainer = styled(Container)`
  padding-top: 95px;
  padding-bottom: 32px;

  ${mediaLg`
    padding-top: 74px;
    padding-bottom: 22px;
  `}

  ${mediaMd`
    padding-top: 62px;
    padding-bottom: 19px;
  `}

  ${mediaSm`
    padding-top: 40px;
    padding-bottom: 50px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h2",
    dark: true,
  },
})`
  margin-bottom: 36px;
  text-align: center;

  ${mediaLg`
    max-width: 777px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 34px;
  `}

  ${mediaMd`
    max-width: 536px;
    margin-left: 0;
    margin-bottom: 10px;
    text-align: left;
  `}

  ${mediaSm`
    margin-bottom: 30px;
  `}
`;

const StyledDuck = styled(Svg).attrs({
  path: "images/artwork-duck-boxed",
})`
  display: block;
  width: 218px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;

  ${mediaLg`
    width: 189px;
    margin-bottom: 63px;
  `}

  ${mediaMd`
    margin-right: 0;
    margin-bottom: 6px;
  `}

  ${mediaSm`
    width: 166px;
    margin-bottom: 43px;
  `}
`;

const StyledQuoteContainer = styled(Column)`
  position: relative;
  z-index: 1;
  margin-top: -104px;

  ${mediaLg`
    margin-top: -70px;
  `}

  ${mediaSm`
    margin-top: 0;
  `}
`;

const StyledSliderContainer = styled.div`
  margin-bottom: 40px;

  ${mediaSm`
    margin-bottom: 0;
  `}
`;

const StyledLogsContainer = styled.div`
  ${mediaMd`
    margin-bottom: 30px;
  `}
`;

const StyledCurve = styled(Svg).attrs({
  path: "images/curve",
  preserveAspectRatio: "none",
})`
  display: block;
  position: absolute;
  z-index: 1;
  bottom: -1px;
  right: -1px;
  left: -1px;
  width: calc(100% + 2px);
  color: ${colors.blackish};
`;

const Features = () => (
  <StyledRoot>
    <StyledContainer>
      <StyledTitle>All the data you need in one place</StyledTitle>
      <StyledDuck />

      <StyledSliderContainer>
        <RealtimeFeature />
      </StyledSliderContainer>
      <StyledLogsContainer>
        <HistoryFeature />
      </StyledLogsContainer>

      <RemoteFeature />

      <Row>
        <StyledQuoteContainer xl={5} lg={5} md={4} sm={4} space={{ xl: 7, lg: 7, md: 4, sm: 0 }}>
          <Quote />
        </StyledQuoteContainer>
      </Row>
    </StyledContainer>
    <StyledCurve />
  </StyledRoot>
);

export default Features;
