import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { sizes, colors } from "src/theme/variables";
import { Typography, Svg } from "src/theme/elements";
import { Row, Column } from "src/theme/layout";
import { lighten } from "polished";
import debounce from "lodash/debounce";
import { bounce } from "src/theme/keyframes";
const slides = ["slider-http", "slider-coredata", "slider-sqlite"];
const controls = ["Debug HTTP Requests", "Browse CoreData content", "Edit SQLite databases"];
const time = "0.4s";
const easing = "ease-in-out";
const offsetStep = 30;
const scaleStep = 0.1;
const lightenStep = 0.075;
const offsetMax = offsetStep * (slides.length - 1);
const defaultAnimationParams = `${time} ${easing}`;
const getNextIndex = index => (index >= slides.length - 1 ? 0 : index + 1);

const StyledTypography = styled(Typography)``;

const StyledRoot = styled(Row)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 60px;
  padding-bottom: 64px;
  background-color: ${colors.white};

  ${mediaLg`
    padding-top: 80px;
    padding-bottom: 85px;
  `}

  ${mediaMd`
    padding-top: 70px;
    padding-bottom: 70px;
  `}

  ${mediaSm`
    padding-top: 32px;
    padding-bottom: 34px;
  `}
`;

const SlyledSlides = styled(Column)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  padding-left: 0;
  padding-right: 0;

  ${mediaMd`
    max-width: 500px;
    margin-bottom: 40px;
  `}

  ${mediaSm`
    max-width: 450px;
    top: 0;
    margin-left: ${sizes.gutter / 2}px;
    margin-right: ${sizes.gutter / 2}px;
    margin-bottom: 25px;
  `}
`;

const StyledContent = styled(Column)`
  display: flex;
  flex-direction: column;
  padding-top: 37px;
  padding-left: 27px;
  padding-right: 0;

  ${mediaLg`
    padding-top: 18px;
  `}

  ${mediaMd`
    padding-top: 0;
  `}

  ${mediaSm`
    padding-left: ${sizes.gutter / 2}px;
    padding-right: ${sizes.gutter / 2}px;
  `}
`;

const StyledSlide = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform-origin: center top;
  transition: transform ${defaultAnimationParams}, opacity ${defaultAnimationParams};
  will-change: transform, opacity;

  ${({ activeSlideIndex, animationState }) => {
    let styles = `
      &:nth-child(${activeSlideIndex + 1}) {
        --background-color: #424350;
        --sidebar-color: #272730;
        --text-color: #c6c6d1;
        --lines-color: #7a7a87;

        z-index: 0;
        transform: translate(-50%, ${offsetMax}px) scale(1);

        ${animationState === "out" &&
          `
              opacity: 0;
              transform: translate(-50%, ${offsetMax}px) scale(0.2);
            `}
      }
    `;

    Array.from(Array(slides.length - 1)).reduce((currentSlideIndex, _, index) => {
      const nextSlideIndex = getNextIndex(currentSlideIndex);
      const incrementedIndex = index + 1;

      styles += `
        &:nth-child(${nextSlideIndex + 1}) {
          --background-color: ${lighten(lightenStep * index, "#dd6478")};
          --sidebar-color: ${lighten(lightenStep * index, "#ca4e63")};
          --text-color: ${lighten(lightenStep * index, "#ffdce2")};
          --lines-color: ${lighten(lightenStep * index, "#f698a8")};

          z-index: -${incrementedIndex};
          transform:
            translate(-50%, ${offsetMax - offsetStep * incrementedIndex}px)
            scale(${1 - scaleStep * incrementedIndex});
        }
      `;

      return nextSlideIndex;
    }, activeSlideIndex);

    return css([styles]);
  }}
`;

const StyledControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 48px;

  ${mediaLg`
    margin-bottom: 40px;
  `}

  ${mediaSm`
    margin-bottom: 34px;
  `}
`;

const StyledControl = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  width: 55%;

  ${mediaMd`
    width: 66%;
  `}

  ${mediaSm`
    width: 83%;
  `}

  &:not(:last-child) {
    margin-bottom: 8px;

    ${mediaSm`
      margin-bottom: 2px;
    `}
  }

  ${StyledTypography} {
    display: block;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    transform-origin: left center;
    transition: transform ${defaultAnimationParams}, font-weight ${defaultAnimationParams};
    will-change: transform;

    ${mediaSm`
      font-size: 18px;
      line-height: 24px;
    `}
  }

  ${({ isActive, animationState }) =>
    isActive &&
    animationState === "in" &&
    css`
      ${StyledTypography} {
        transform: scale(1.8);
        font-weight: 700;

        ${mediaMd`
          transform: scale(1.5);
        `}

        ${mediaSm`
          transform: scale(1.2);
        `}
      }

      ${StyledProgressContainer} {
        transition: opacity ${defaultAnimationParams};
        opacity: 1;
      }

      ${StyledProgress} {
        transition: transform 4s linear ${time};
        transform: translateY(0);
      }
    `}
`;

const StyledProgressContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: -27px;
  width: 3px;
  height: 64px;
  background-color: rgba(33, 33, 41, 0.15);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ${easing};
  will-change: opacity;

  ${mediaSm`
    left: -20px;
    height: 44px;
  `}
`;

const StyledProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.blackish};
  transform: translateY(-64px);
  transition: transform 0s linear 0.2s;
  will-change: transform;

  ${mediaSm`
    transform: translateY(-44px);
  `}
`;

const StyledLabel = styled(Typography)`
  color: ${colors.crimson};
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 16px;

  ${mediaLg`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
  `}

  ${mediaSm`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  `}
`;

const StyledBackSvg = styled(Svg).attrs({
  path: "images/backshape-red",
})`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 270px;

  ${mediaLg`
    left: 190px;
  `}

  ${mediaMd`
    top: 104px;
    bottom: 104px;
    height: calc(100% - 208px);
  `}

  ${mediaSm`
    left: 72px;
    right: auto;
  `}
`;

const StyledArrow = styled(Svg).attrs({
  path: "icons/double-arrow",
})`
  width: 24px;
  color: ${colors.crimson};
  animation: ${bounce} 2s linear infinite;
`;

const Slide = React.forwardRef(({ svg, activeSlideIndex, animationState }, ref) => (
  <StyledSlide ref={ref} activeSlideIndex={activeSlideIndex} animationState={animationState}>
    <Svg path={svg} />
  </StyledSlide>
));

Slide.displayName = "Slide";

Slide.propTypes = {
  svg: PropTypes.node,
  activeSlideIndex: PropTypes.number,
  animationState: PropTypes.oneOf(["in", "out"]),
};

const Control = React.forwardRef(({ children, isActive, animationState }, ref) => {
  const controlRef = useRef(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      controlRef.current.style.cssText = `margin: ${controlRef.current.offsetHeight / 2}px 0;`;
    }, 200);

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StyledControl ref={controlRef} isActive={isActive} animationState={animationState}>
      <StyledProgressContainer>
        <StyledProgress ref={ref} />
      </StyledProgressContainer>

      <StyledTypography settings={{ dark: true }}>{children}</StyledTypography>
    </StyledControl>
  );
});

Control.displayName = "Control";

Control.propTypes = {
  children: PropTypes.string,
  isActive: PropTypes.bool,
  animationState: PropTypes.oneOf(["in", "out"]),
};

const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(slides.length - 1);
  const [animationState, setAnimationState] = useState("out");

  const containerRef = useRef(null);
  const slidesContainerRef = useRef(null);
  const slideRefs = useRef(slides.map(React.createRef));
  const progressRefs = useRef(slides.map(React.createRef));

  const changeActiveSlide = () => {
    setAnimationState("in");
    setActiveSlide(getNextIndex(activeSlide));
  };

  useEffect(() => {
    progressRefs.current[activeSlide].current.addEventListener(
      "transitionend",
      () => {
        setTimeout(() => setAnimationState("out"));

        slideRefs.current[activeSlide].current.addEventListener(
          "transitionend",
          () => {
            setTimeout(changeActiveSlide);
          },
          { once: true },
        );
      },
      { once: true },
    );
  }, [activeSlide]);

  useEffect(() => {
    let screenHeight = window.innerHeight;
    let containerOffsetTop = containerRef.current.offsetTop;

    const handleScroll = () => {
      if (window.scrollY + screenHeight > containerOffsetTop) {
        changeActiveSlide();

        window.removeEventListener("scroll", handleScroll);
      }
    };

    const handleResize = debounce(() => {
      screenHeight = window.innerHeight;
      containerOffsetTop = containerRef.current.offsetTop;
      slidesContainerRef.current.style.height = `${slideRefs.current[0].current.offsetHeight + offsetMax}px`;
    }, 200);

    handleScroll();
    handleResize();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledRoot ref={containerRef}>
      <StyledBackSvg />
      <SlyledSlides ref={slidesContainerRef} xl={5} lg={5} md={5} sm={4} space={{ xl: 0.5, lg: 0.5, md: 0.5, sm: 0 }}>
        {slides.map((symbol, index) => (
          <Slide
            key={index}
            ref={slideRefs.current[index]}
            svg={symbol}
            activeSlideIndex={activeSlide}
            animationState={animationState}
          />
        ))}
      </SlyledSlides>
      <StyledContent xl={5} lg={6} md={7.5} sm={4} space={{ xl: 1, lg: 0.5, md: 0.5, sm: 0 }}>
        <StyledLabel>Realtime Monitoring</StyledLabel>
        <StyledControls>
          {controls.map((text, index) => (
            <Control
              key={index}
              ref={progressRefs.current[index]}
              isActive={index === activeSlide}
              animationState={animationState}
            >
              {text}
            </Control>
          ))}
        </StyledControls>
        <StyledArrow />
      </StyledContent>
    </StyledRoot>
  );
};

export default Slider;
