import React from "react";
import styled from "styled-components";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Typography, Svg } from "src/theme/elements";
import { fonts } from "src/theme/variables";

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mediaSm`
    align-items: center;
    padding-top: 40px;
    padding-bottom: 30px;
  `}
`;

const StyledQuoteIcon = styled(Svg).attrs({
  path: "icons/quote",
})`
  align-self: flex-start;
  color: #c6c6d1;
  width: 34px;
  margin-bottom: 30px;

  ${mediaLg`
    width: 29px;
    margin-bottom: 23px;
  `}

  ${mediaSm`
    width: 30px;
    margin-bottom: 27px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h3",
    dark: true,
  },
})`
  font-size: 30px;
  line-height: 44px;
  margin-bottom: 26px;
  text-align: right;
  font-family: ${fonts.tertiary};

  ${mediaLg`
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 25px;
  `}

  ${mediaMd`
    margin-bottom: 13px;
  `}

  ${mediaSm`
    text-align: center;
  `}
`;

const StyledAuthor = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mediaSm`
    flex-direction: column-reverse;
  `}
`;

const StyledName = styled(Typography).attrs({
  settings: {
    dark: true,
  },
})`
  display: block;
  font-size: 16px;
  line-height: 25px;
  text-align: right;
  font-weight: 500;

  ${mediaSm`
    text-align: center;
  `}
`;

const Quote = () => {
  return (
    <StyledRoot>
      <StyledQuoteIcon />
      <StyledTitle>It is a very unique and useful tool for tracking down performance issues.</StyledTitle>
      <StyledAuthor>
        <div>
          <StyledName>Rok Gregoric, CTO, Closer</StyledName>
        </div>
      </StyledAuthor>
    </StyledRoot>
  );
};
export default Quote;
