import React from "react";
import styled from "styled-components";
import { Typography, Button, Svg } from "src/theme/elements";
import { Container, Row, Column, Buttons } from "src/theme/layout";
import { colors, fonts, sizes } from "src/theme/variables";
import { openChat } from "src/utils";
import { mediaMd, mediaSm } from "src/theme/utils";

const StyledRoot = styled.div`
  background-color: ${colors.white};
`;

const StyledContainer = styled(Container)`
  padding-top: 52px;
  padding-bottom: 99px;

  ${mediaSm`
    padding-top: 37px;
    padding-bottom: 36px;
  `}
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: flex-start;

  ${mediaSm`
    flex-direction: column;
  `}
`;

const StyledContent = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;

  ${mediaMd`
    padding-left: ${sizes.gutter / 2}px;
  `}

  ${mediaSm`
    align-items: center;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h3",
    dark: true,
  },
})`
  margin-bottom: 14px;

  ${mediaSm`
    text-align: center;
  `};
`;

const StyledText = styled(Typography).attrs({
  settings: {
    tag: "p",
    dark: true,
  },
})`
  margin-bottom: 21px;
  font-size: 20px;
  line-height: 27px;
  font-family: ${fonts.secondary};

  ${mediaSm`
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  `};
`;

const StyledDuck = styled(Svg).attrs({
  path: "images/operator-duck",
})`
  width: 240px;
  max-width: 100%;
  margin: 0 auto;

  ${mediaSm`
    width: 196px;
    margin-bottom: 37px;
  `}
`;

const Contact = () => (
  <StyledRoot>
    <StyledContainer>
      <StyledRow>
        <Column xl={3} lg={3} md={3} sm={4} space={{ xl: 1, lg: 1 }}>
          <StyledDuck />
        </Column>
        <StyledContent xl={6.5} lg={6.5} md={5} sm={4} space={{ xl: 0.5, lg: 0.5 }}>
          <StyledTitle>We love dialogue</StyledTitle>
          <StyledText>
            Maybe you need extra support or maybe you simply have some questions. Ask away!
          </StyledText>
          <Buttons>
            <Button to="mailto:info@appspector.com" icon="mail">
              Email Us
            </Button>
            <Button variant="bordered" icon="chat" onClick={openChat}>
              Open Chat
            </Button>
          </Buttons>
        </StyledContent>
      </StyledRow>
    </StyledContainer>
  </StyledRoot>
);

export default Contact;
