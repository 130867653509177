import React from "react";
import styled from "styled-components";
import { Container } from "src/theme/layout";
import { Typography, Svg } from "src/theme/elements";
import { vwMd, mediaLg, mediaMd, mediaSm } from "src/theme/utils";
import { colors, fonts } from "src/theme/variables";

const StyledRoot = styled.section`
  position: relative;
  padding-bottom: ${vwMd(24)};
  background-color: ${colors.blackish};
`;

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 66px 0 88px;
  margin: 0 -65px;

  ${mediaLg`
    padding: 42px 0 46px;
    margin: 0 -51px;
  `}

  ${mediaMd`
    flex-direction: column;
  `}

  ${mediaSm`
    padding: 10px 0 30px;
    margin: 0;
  `}
`;

const StyledItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 65px;

  ${mediaLg`
    padding: 20px 51px;
  `}

  ${mediaSm`
    flex: none;
    width: 100%;
    padding: 30px 0;
  `}
`;

const StyledIcon = styled(Svg)`
  height: 122px;
  margin-left: auto;
  margin-right: auto;

  svg {
    height: 100%;
  }

  ${mediaLg`
    height: 88px;
    margin-bottom: 24px;
  `}

  ${mediaSm`
    height: 60px;
    margin-bottom: 12px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h4",
  },
})`
  text-align: center;
  font-size: 180%;
  margin-bottom: 6px;

  ${mediaLg`
    margin-bottom: 11px;
  `}

  ${mediaMd`
    margin-bottom: 9px;
  `}

  ${mediaSm`
    margin-bottom: 1px;
  `}
`;

const StyledText = styled(Typography)`
  font-family: ${fonts.secondary};
  text-align: center;
`;

const StyledCurve = styled(Svg).attrs({
  path: "images/curve",
  preserveAspectRatio: "none",
})`
  position: absolute;
  z-index: 2;
  left: -1px;
  right: -1px;
  bottom: -1px;
  width: calc(100% + 2px);
  color: ${colors.white};
`;

const items = [
  {
    icon: "images/realtime-pulse",
    title: "Realtime Monitoring",
    text: "Analyze your app and discover bugs as they arise.",
  },
  {
    icon: "images/replay-time",
    title: "Historic Replay",
    text: "Find and reproduce past bugs as needed.",
  },
  {
    icon: "images/remote-eye",
    title: "Remote Access",
    text: "Connect and control your app from anywhere.",
  },
];

const Benefits = () => (
  <StyledRoot>
    <Container>
      <StyledItems>
        {items.map(({ title, text, icon }, index) => (
          <StyledItem key={index}>
            <StyledIcon path={icon}></StyledIcon>
            <StyledTitle>{title}</StyledTitle>
            <StyledText>{text}</StyledText>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
    <StyledCurve />
  </StyledRoot>
);

export default Benefits;
