import React from "react";
import styled from "styled-components";
import { sizes, colors } from "src/theme/variables";
import { vwMd, mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Container, Row, Column } from "src/theme/layout";
import { Typography, Svg } from "src/theme/elements";
import Security from "./elements/Security";

const data = [
  {
    icon: "icons/performance",
    text: "Performance",
  },
  {
    icon: "icons/network",
    text: "Network",
  },
  {
    icon: "icons/logs",
    text: "Logs",
  },
  {
    icon: "icons/analytics",
    text: "Analytics",
  },
  {
    icon: "icons/environment",
    text: "Environment",
  },
  {
    icon: "icons/coredata",
    text: "Core Data",
  },
  {
    icon: "icons/location",
    text: "Location",
  },
  {
    icon: "icons/screenshot",
    text: "Screenshots",
  },
  {
    icon: "icons/notifications",
    text: "Notifications",
  },
  {
    icon: "icons/sqlite",
    text: "SQLite",
  },
  {
    icon: "icons/user-defaults",
    text: "User Defaults",
  },
  {
    icon: "icons/file",
    text: "File System",
  },
  {
    icon: "icons/events",
    text: "Custom Events",
  },
  {
    icon: "icons/commands",
    text: "Custom Commands",
  },
];

const StyledRoot = styled.div`
  position: relative;
  z-index: 1;
  padding-bottom: ${vwMd(24)};
  background-color: ${colors.white};
`;

const StyledContainer = styled(Container)`
  padding-top: 32px;

  ${mediaLg`
    padding-top: 46px;
  `}

  ${mediaSm`
    padding-top: 40px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h2",
    dark: true,
  },
})`
  margin-bottom: 40px;

  ${mediaLg`
    margin-bottom: 34px;
  `}

  ${mediaSm`
    margin-bottom: 30px;
  `}
`;

const StyledItems = styled.div`
  max-width: calc(275px * 3);
  max-height: calc(58px * 5);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 90px;

  ${mediaLg`
    margin-bottom: 80px;
  `}

  ${mediaMd`
    max-width: calc(230px * 3);
    max-height: calc(90px * 5);
  `}

  ${mediaSm`
    max-width: none;
    max-height: none;
    flex-direction: row;
    margin-left: -${sizes.gutter / 2}px;
    margin-right: -${sizes.gutter / 2}px;
  `}
`;

const StyledItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 275px;
  padding-right: 16px;
  color: ${({ soon }) => (soon ? colors.darkGray : colors.blackish)};
  margin-bottom: ${({ soon }) => (soon ? 10 : 24)}px;

  ${mediaMd`
    width: 230px;
    flex-direction: column;
  `}

  ${mediaSm`
    width: 50%;
    padding-left: ${sizes.gutter / 2}px;
    padding-right: ${sizes.gutter / 2}px;
  `}
`;

const StyledIcon = styled(Svg)`
  width: 32px;
  height: 32px;
  color: inherit;
  margin-right: 16px;

  ${mediaSm`
    width: 24px;
    height: 24px;
    margin-right: 0;
    margin-bottom: 4px;
  `}
`;

const StyledName = styled(Typography)`
  display: block;
  line-height: 32px;
  margin-bottom: 2px;
  color: inherit;

  ${mediaSm`
    font-size: 18px;
    line-height: 24px;
  `}
`;

const StyledSoon = styled(Typography)`
  display: block;
  color: inherit;
  font-size: 9px;
  line-height: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;

  ${mediaSm`
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

const StyledBottom = styled(Row)`
  position: relative;
  z-index: 2;
  margin-bottom: calc(-140px - ${vwMd(24)});

  ${mediaLg`
    margin-bottom: calc(-120px - ${vwMd(24)});
  `}

  ${mediaMd`
    margin-top: 0;
  `}

  ${mediaSm`
    margin-bottom: calc(-104px - ${vwMd(24)});
  `}
`;

const Scope = () => (
  <StyledRoot>
    <StyledContainer>
      <StyledTitle>Full Scope</StyledTitle>
      <StyledItems>
        {data.map(({ icon, text, soon }, index) => (
          <StyledItem key={index} soon={soon}>
            <StyledIcon path={icon} />
            <div>
              <StyledName settings={{ dark: true }}>{text}</StyledName>
              {soon && <StyledSoon>Coming soon</StyledSoon>}
            </div>
          </StyledItem>
        ))}
      </StyledItems>
      <StyledBottom>
        <Column xl={7} lg={6.5} md={6} sm={4} space={{ xl: 5, lg: 5.5, md: 2, sm: 0 }}>
          <Security />
        </Column>
      </StyledBottom>
    </StyledContainer>
  </StyledRoot>
);

export default Scope;
