import React from "react";
import styled from "styled-components";
import { sizes, colors, fonts } from "src/theme/variables";
import { mediaSm, mediaMd, mediaLg } from "src/theme/utils";
import { Typography, Svg, Image } from "src/theme/elements";
import { Row, Column } from "src/theme/layout";
import { bounce } from "src/theme/keyframes";

const StyledRoot = styled(Row)`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  padding-top: 128px;
  padding-bottom: 128px;
  background-color: ${colors.white};

  ${mediaLg`
    padding-top: 143px;
    padding-bottom: 143px;
  `}

  ${mediaMd`
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 163px;
  `}

  ${mediaSm`
    padding-top: 96px;
    padding-bottom: 102px;
  `}
`;

const StyledContent = styled(Column)`
  display: flex;
  flex-direction: column;
  padding-left: calc(24px + ${sizes.gutter}px);
  padding-right: calc(24px + ${sizes.gutter}px);

  ${mediaSm`
    padding-left: ${sizes.gutter / 2}px;
    padding-right: ${sizes.gutter / 2}px;
  `}
`;

const StyledLabel = styled(Typography)`
  color: ${colors.green};
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 16px;

  ${mediaLg`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 9px;
  `}

  ${mediaSm`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h3",
    dark: true,
  },
})`
  margin-bottom: 14px;

  ${mediaSm`
    margin-bottom: 11px;
  `}
`;

const StyledText = styled(Typography).attrs({
  settings: {
    tag: "p",
    dark: true,
  },
})`
  font-family: ${fonts.secondary};
  margin-bottom: 50px;

  ${mediaSm`
    margin-bottom: 32px;
  `}
`;

const StyledLocationImage = styled(Image).attrs({
  path: "/assets/new/location.png",
  alt: "Location",
})`
  width: 100%;

  ${mediaMd`
    max-width: 550px;
    margin-bottom: 40px;
  `}

  ${mediaSm`
    max-width: 500px;
    width: calc(100% + 56px);
    margin-bottom: 30px;
    margin-left: -28px;
    margin-right: -28px;
  `}
`;

const StyledBackSvg = styled(Svg).attrs({
  path: "images/backshape-green",
})`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 385px;

  ${mediaLg`
    left: 275px;
  `}

  ${mediaMd`
    top: 72px;
    left: 100px;
    height: calc(100% - 72px);
  `}

  ${mediaSm`
    left: 72px;
  `}
`;

const StyledArrow = styled(Svg).attrs({
  path: "icons/double-arrow",
})`
  width: 24px;
  color: ${colors.green};
  animation: ${bounce} 2s linear infinite;
`;

const LocationDetails = () => (
  <StyledRoot>
    <StyledBackSvg />
    <Column xl={6} lg={6} md={8} sm={4}>
      <StyledLocationImage />
    </Column>
    <StyledContent xl={6} lg={6} md={7} sm={4}>
      <StyledLabel>Remote Access:</StyledLabel>
      <StyledTitle>Connect and control your app from anywhere</StyledTitle>
      <StyledText>Support users and solve bugs without physical access to the device</StyledText>
      <StyledArrow />
    </StyledContent>
  </StyledRoot>
);

export default LocationDetails;
