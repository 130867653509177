import React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "src/theme/variables";
import { mediaLg, mediaMd, mediaSm } from "src/theme/utils";
import { Typography, Svg } from "src/theme/elements";
import { Container, Row, Column } from "src/theme/layout";
import Header from "src/elements/Header";
import Platforms from "./elements/Platforms";
import Scene from "./elements/Scene";
import { AccentLinkButton } from "src/components/Common";

const StyledRoot = styled.section`
  position: relative;
  background-image: linear-gradient(to top, #a8a8bb, #323249);
  overflow: hidden;
`;

const StyledRow = styled(Row)`
  position: relative;
  z-index: 3;
  padding: 34px 0 100px;

  ${mediaLg`
    padding: 38px 0 20px;
  `}

  ${mediaMd`
    padding: 36px 0 20px;
  `}
`;

const StyledTitle = styled(Typography).attrs({
  settings: {
    tag: "h1",
  },
})`
  margin-bottom: 32px;

  ${mediaLg`
    margin-bottom: 24px;
  `}

  ${mediaMd`
    margin-bottom: 13px;
  `}
`;

const StyledSubtitle = styled(Typography).attrs({
  settings: {
    tag: "p",
  },
})`
  font-size: 24px;
  line-height: 34px;
  font-family: ${fonts.secondary};

  ${mediaLg`
    font-size: 22px;
    line-height: 30px;
  `}

  ${mediaSm`
    font-size: 20px;
    line-height: 27px;
  `}
`;

const StyledScene = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;

  ${mediaLg`
    max-width: ${breakpoints.md}px;
    left: auto;
  `}

  ${mediaMd`
    position: relative;
    max-width: ${breakpoints.sm}px;
    margin-left: auto;
    margin-top: -150px;
  `}

  ${mediaSm`
    position: relative;
    max-width: ${breakpoints.xs}px;
    bottom: -17px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    margin-left: 0;
    margin-top: 0;
  `}
`;

const StyledCurve = styled(Svg).attrs({
  path: "images/curve",
  preserveAspectRatio: "none",
})`
  position: absolute;
  z-index: 2;
  left: -1px;
  right: -1px;
  bottom: -1px;
  width: calc(100% + 2px);
  color: ${colors.blackish};
`;

const ActionButton = styled(AccentLinkButton)`
  width: 200px;
  height: 46px;
  margin: 28px 0;
`;

const MainScreen = () => (
  <StyledRoot>
    <Header />
    <Container>
      <StyledRow>
        <Column xl={6} lg={6} md={6} sm={4}>
          <StyledTitle>Remote debugging for iOS and Android apps</StyledTitle>
        </Column>
        <Column xl={6} lg={6} md={6} sm={4}>
          <StyledSubtitle>
            Access all essential mobile application internals in realtime, wherever you are
          </StyledSubtitle>
          <ActionButton href="https://app.appspector.com/register" target="_blank">
            Try for Free
          </ActionButton>
        </Column>
        <Column xl={3} lg={4} md={3} sm={4}>
          <Platforms />
        </Column>
      </StyledRow>
    </Container>
    <StyledScene>
      <Scene />
    </StyledScene>
    <StyledCurve />
  </StyledRoot>
);

export default MainScreen;
